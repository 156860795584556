export function initTableScroll() {
  function syncScroll() {
    let topScroll = document.getElementById('table-top-scroll');
    let bottomScroll = document.querySelector('.table-responsive');

    if (topScroll && bottomScroll) {
      if (bottomScroll.scrollWidth <= bottomScroll.clientWidth) {
        topScroll.style.visibility = 'hidden';
        topScroll.style.height = '0';
      } else {
        topScroll.style.visibility = 'visible';
        topScroll.style.height = '10';
      }
      window.floatThead();

      if (!document.getElementById("horizontal-scroll-content")) {
        let scrollContent = document.createElement("div");
        scrollContent.id = "horizontal-scroll-content";
        scrollContent.style.width = bottomScroll.scrollWidth + "px";
        scrollContent.style.height = "1px";
        topScroll.appendChild(scrollContent);

        topScroll.addEventListener("scroll", () => {
          bottomScroll.scrollLeft = topScroll.scrollLeft;
        });

        bottomScroll.addEventListener("scroll", () => {
          topScroll.scrollLeft = bottomScroll.scrollLeft;
        });
      }
    }
  }

  syncScroll();

  document.addEventListener('DOMContentLoaded', syncScroll);
  document.addEventListener('livewire:load', syncScroll);
  document.addEventListener('livewire:update', syncScroll);
}
